// Generated by Framer (9acc586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/C31a5jsoI7tLti4X41zP/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["x3TtXg53f"];

const variantClassNames = {x3TtXg53f: "framer-v-y2fr5x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "x3TtXg53f", title: L9CtqpHc6 = "About me", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "x3TtXg53f", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-k5mbQ", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-y2fr5x", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"x3TtXg53f"} ref={ref} style={{"--border-bottom-width": "2px", "--border-color": "var(--token-f80edf8c-c00e-4655-b68f-9f20fa67942d, rgb(29, 29, 33)) /* {\"name\":\"Dark Grey\"} */", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", backgroundColor: "rgb(34, 170, 153)", ...style}} transition={transition}><Text __fromCanvasComponent className={"framer-ygw7ne"} fonts={["GF;DM Sans-500"]} layoutDependency={layoutDependency} layoutId={"OJelCFcaA"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='direction: ltr; font-size: 0'><span style=''>About me</span><br></span></span>"} style={{"--framer-font-family": "\"DM Sans\", sans-serif", "--framer-font-size": "20px", "--framer-font-style": "normal", "--framer-font-weight": 500, "--framer-letter-spacing": "0px", "--framer-line-height": "1.2em", "--framer-text-alignment": "start", "--framer-text-color": "var(--token-f80edf8c-c00e-4655-b68f-9f20fa67942d, rgb(29, 29, 33))", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={L9CtqpHc6} transition={transition} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-aouxop-container"} layoutDependency={layoutDependency} layoutId={"oGpog3IP3-container"} transition={transition}><Phosphor color={"var(--token-f80edf8c-c00e-4655-b68f-9f20fa67942d, rgb(29, 29, 33)) /* {\"name\":\"Dark Grey\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"ArrowRight"} id={"oGpog3IP3"} layoutId={"oGpog3IP3"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-k5mbQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-k5mbQ * { box-sizing: border-box; }", ".framer-k5mbQ .framer-y2fr5x { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 51px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 32px 16px 32px; position: relative; width: min-content; }", ".framer-k5mbQ .framer-ygw7ne { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", ".framer-k5mbQ .framer-aouxop-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-k5mbQ .framer-y2fr5x { gap: 0px; } .framer-k5mbQ .framer-y2fr5x > * { margin: 0px; margin-left: calc(51px / 2); margin-right: calc(51px / 2); } .framer-k5mbQ .framer-y2fr5x > :first-child { margin-left: 0px; } .framer-k5mbQ .framer-y2fr5x > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 232
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"L9CtqpHc6":"title"}
 */
const FramerghBZNcy88: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerghBZNcy88;

FramerghBZNcy88.displayName = "Button";

FramerghBZNcy88.defaultProps = {height: 56, width: 232};

addPropertyControls(FramerghBZNcy88, {L9CtqpHc6: {defaultValue: "About me", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerghBZNcy88, [{family: "DM Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/ghBZNcy88:default", url: "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf", weight: "500"}, ...PhosphorFonts])